<template>
  <div class="serchbox">
    <div>
      <el-row>
        <el-col :span="6">
          <el-button style="margin-left:50px" type="danger" @click="addbug()">添加BUG</el-button>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-dialog
        title="BUG表单"
        :inline="true"
        class="demo-form-inline dialog"
        width="80%"
        :visible.sync="dialogFormVisible"
      >
        <div class="formbiaodan">
          <el-form v-model="form">
            <el-input type="hidden" style="width:300px" placeholder="请输入名称" v-model="form.id"></el-input>

            <el-row>
              <el-col :span="12" style="position: relative;">
                <span
                  style="position: absolute;
    color: red;
    font-size: 1px;
    top: 14px;
    left: -13px;"
                  class="el-icon-star-off"
                ></span>
                <el-form-item label="BUG问题模块:">
                  <el-select
                    :disabled="watch"
                    v-model="form.moduleid"
                    style="width:300px"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in bugmodules"
                      :key="item.id"
                      :label="item.module_name"
                      :value="item.mid"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="position: relative;">
                <span
                  style="position: absolute;
    color: red;
    font-size: 1px;
    top: 14px;
    left: -13px;"
                  class="el-icon-star-off"
                ></span>
                <el-form-item label=" 技术员:" prop="name">
                  <el-select
                    :disabled="watch"
                    v-model="form.fixmanid"
                    style="width:300px"
                    placeholder="请选择技术员"
                  >
                    <el-option
                      v-for="item in fixmans"
                      :key="item.id"
                      :label="item.man_name"
                      :value="item.fid"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-show="solvshow" :span="24" style="margin-top:30px;position: relative;">
                <span
                  style="position: absolute;
    color: red;
    font-size: 1px;
    top: 14px;
    left: -13px;"
                  class="el-icon-star-off"
                ></span>
                <el-form-item label="处理情况:" prop="name">
                  <el-select
                    :disabled="watch"
                    v-model="form.solve"
                    style="width:300px"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in editsolves"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="24" style="display: flex;position: relative;">
                <span
                  style="position: absolute; color: red;font-size: 1px;top: 14px;left: -13px;"
                  class="el-icon-star-off"
                ></span>
                <el-form-item label="BUG内容:">
                  <br />
                  <div class="wendang" v-show="watch">
                    <p v-html="wentext"></p>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-dialog>
    </div>

    <div class="datalist">
      <div>
        <el-table
          v-loading="loading"
          :data="tableData"
          :row-class-name="tableRowClassName"
          border
          style="width: 85%;margin:0 auto"
        >
          <el-table-column label="序号" width="50">
            <template slot-scope="scope">{{scope.$index+1}}</template>
          </el-table-column>
          <el-table-column width="100px" prop="module" label="BUG模块"></el-table-column>
          <el-table-column width="350px" label="BUG图片">
            <template slot-scope="scope">
              <div style="display:flex;overflow:auto">
                <li style="flex: 1" v-for="item in scope.row.img" :key="item.id">
                  <img
                    style="max-width: 80px;
    padding: 8px 6px;"
                    :src="item.url"
                    @click="getimages(scope.row.img,item.url)"
                  />
                </li>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100px" prop="fixman" label="技术员"></el-table-column>
          <el-table-column width="120px" label="是否解决">
            <template slot-scope="scope">
              <el-select @change="editsolve(scope.row)" v-model="scope.row.solve" placeholder="请选择">
                <el-option
                  v-for="item in editsolves"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                v-show="scope.row.canedit"
                effect="dark"
                content="修改"
                placement="top-start"
              >
                <a href="#" @click="edit(scope.row)" style="margin-left: 10px;">
                  <i class="el-icon-edit"></i>
                </a>
              </el-tooltip>
              <el-tooltip
                class="item"
                v-show="scope.row.canedit"
                effect="dark"
                content="删除"
                placement="top-start"
              >
                <button
                  class="delbtn"
                  style="border:none;background:none"
                  @click="delecomfir(scope.row.id)"
                >
                  <i class="el-icon-takeaway-box"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="item" effect="dark" content="详情" placement="top-start">
                <a href="#" @click="edit(scope.row,'look')" style="margin-left: 10px;">
                  <i class="el-icon-view"></i>
                </a>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage2"
        :page-sizes="[10, 30, 100, 200]"
        :page-size="pagesize"
        layout="sizes, prev, pager, next"
        :total="pagetotal"
      ></el-pagination>
    </div>
    <div class="watchimage" v-show="imageshow">
      <span class="el-icon-close" @click="imageshow=false"></span>
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in images" :key="item">
          <img class="hdpimg" :src="item.url" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buglevel: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "低",
        },
        {
          value: "2",
          label: "中",
        },
        {
          value: "3",
          label: "高",
        },
      ], //bug等级
      isClear: false,
      bugmodules: [],
      loading: true,
      fixmans: [],
      adduser: "",
      solves: [
        {
          value: 3,
          label: "全部",
        },
        {
          value: 0,
          label: "未解决",
        },
        {
          value: 1,
          label: "已解决",
        },
        {
          value: 2,
          label: "搁置",
        },
      ],
      form: {
        id: "",
        moduleid: "",
        fixmanid: "",
        text: "",
        image: [],
        solve: "0",
      },
      editcontent: "",

      editsolves: [
        {
          value: 0,
          label: "未解决",
        },
        {
          value: 1,
          label: "已解决",
        },
        {
          value: 2,
          label: "搁置",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      solvshow: false,
      serchval: {
        bugname: "",
        buglevelvalue: "0", //等级值
        modulevalue: "0",
        fixmanid: "0",
        solvevalue: 3,
        timevalue2: "",
      },
      tableData: [],
      pagetotal: 0, //总数据量
      currentPage2: 1,
      pagesize: 10,
      dialogFormVisible: false,

      imgsrc: [],
      formLabelWidth: "120px",
      images: [],
      imageshow: false,
      imgdef: "",
      watch: false,
      wentext: "BUG内容展示",
    };
  },

  mounted() {
    this.getset();
    this.getdata(10, 1);
  },

  methods: {
    qxsubmit() {
      this.getdata(this.pagesize, this.currentPage2);
      this.dialogFormVisible = false;
    },

    edit(data, type) {
      if (type == "look") {
        this.solvshow = true;
        this.$store.state.dict.image["BUG_image"] = [];
        this.form.id = data.id;
        this.form.moduleid = parseInt(data.moduleid);
        this.form.leavel = data.leavelnum + "";
        this.form.fixmanid = parseInt(data.fixmanid);
        this.editcontent = data.text;
        this.form.text = data.text;
        this.form.image = data.img;
        this.$store.state.dict.image["BUG_image"] = data.img;
        this.form.solve = parseInt(data.sovlenum);
        this.dialogFormVisible = true;
        this.wentext = data.text;
        this.watch = true;
      } else {
        this.watch = false;
        this.$router.push({
          path: "/bug/addbug",
          query: { type: "edit", datas: data },
        });
      }
    },
    handleSizeChange(val) {
      //todo 数据库查询
      this.pagesize = val;
      this.getdata(this.pagesize, this.currentPage2);
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getdata(this.pagesize, this.currentPage2);
    },
    tableRowClassName({ row }) {
      if (row.sovlenum === 0) {
        return "warning-row";
      } else if (row.sovlenum === 1) {
        return "success-row";
      }
      return "gezhi-row";
    },
    //清除表单

    delecomfir(id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //todo 数据库操作
          let that = this;
          this.$axios
            .post("/bug/index/delete", {
              id: JSON.parse(JSON.stringify(id)),
            })
            .then((res) => {
              if (res.code == 200) {
                this.loading = true;
                this.getdata(that.pagesize, that.currentPage2);
                this.$message.success("删除成功!");
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获取数据
    getdata(num, page) {
      let that = this;
      let pages = {
        num: num,
        page: page,
      };
      this.$axios
        .post("/bug/index/getdata", JSON.parse(JSON.stringify(pages)))
        .then((res) => {
          if (res.code == 200) {
            that.tableData = res.data;
            that.pagetotal = res.count;
            that.loading = false;
          } else {
            this.$message.error("服务区打盹了,请稍后重试.....");
          }
        });
    },
    //获取配置信息
    getset() {
      let that = this;
      this.$axios.post("/bug/index/getset").then((res) => {
        if (res.code == 200) {
          that.fixmans = res.data.fixmans;
          that.bugmodules = res.data.modules;
        } else {
          this.$message.error("服务区打盹了,请稍后重试.....");
        }
      });
    },

    addbug() {
      this.watch = false;
      this.solvshow = false;
      this.$store.state.dict.image["BUG_image"] = [];
      this.$router.push({ path: "/bug/addbug", query: { type: "add" } });
    },

    editsolve(data) {
      this.$axios
        .post("/bug/index/editsovle", {
          id: data.id,
          solve: data.solve,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("修改成功");
            this.loading = true;
            this.getdata(this.pagesize, this.currentPage2);
          }
        });
    },
    getimages(row, url) {
      this.imageshow = true;
      let that = this;
      this.images = row;
      this.images.map((v, i) => {
        if (v.url == url) that.imgdef = i;
      });
    },
    restartform() {
      this.editcontent = "";
      this.form.id = "";
      this.form.moduleid = "";
      this.form.leavel = "";
      this.form.fixmanid = "";
      this.form.text = "";
      this.form.image = [];
      this.solve = -1;
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.serchbox {
  margin-top: 10px;
}
.datalist {
  height: 70vh;
  width: 95vw;
  overflow: auto;
  position: relative;
  text-align: center;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  box-shadow: 0px 1px 1px black;
}
.el-table td,
.el-table th {
  text-align: center;
}
.page {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
}
.delbtn {
  border: none;
  width: 20px;
  color: red;
}
li {
  list-style: none;
}
.el-dialog {
  margin: 0 auto !important;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.watchimage {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 634px;
  transform: translate(-40%, -50%);
}
.el-table .warning-row {
  background: #ff7675;
}

.el-table .success-row {
  background: #00cec9;
}
.el-table .gezhi-row {
  background: #b2bec3;
}
.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
}
.hdpimg {
  max-height: 500px;
  max-width: 500px;
}
.watchimage span {
  right: 11px;
  position: absolute;
  z-index: 99;
  top: 7px;
}
.el-form-item__content {
  width: 100% !important;
}
.el-carousel__container {
  height: 500px !important;
}
.wendang {
  width: 1050px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>